<template>
    <div @click="bigPic" class="picture" :style="{width:width+'px',height:width+'px',overflow:hidden}">
        <img :src="url" alt="" width="100%" height="100%">
        <div v-if="!url" class="el-icon-picture-outline picc" :style="{lineHeight:width+'px',width:width+'px'}"></div>
        <ElImageViewer
        z-index='1000000'
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="picList"
        ></ElImageViewer>
    </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'

export default {
    components:{
        ElImageViewer
    },
    props:{
        width:{
            default:'60',
        },
        url:{
            type:String
        }
    },
    data(){
        return{
            picList:[],
            showViewer:false
        }
    },
    mounted(){
        this.picList=[this.url]
    },
    methods:{
        bigPic(){
            if(!this.url) return
            this.showViewer=true
        },
        closeViewer(){
            this.showViewer=false
        }
    }
}
</script>

<style scope>
.picture{
    position: relative;
}
.picc{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: #EBEEF5;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'


Vue.use(VueRouter)


const Supplier = ()=> 
   import("../views/Procurement/supplier.vue");

const BalaPayAccount = () =>
  import("../views/financialManage/account.vue");

const procurementPlan = () => import("../views/Procurement/procurementPlan.vue")

const routes = [
  {
    path:'/shopify',
    component:()=>import('../views/shopAuthorization/test.vue')
  },
  {
    path:'/',
    redirect:'/login'
  },
  {
    path:'/login',
    name:'login',
    component:()=>import('../views/Login.vue'),
    meta:{title:'登录'},
  },
  {
    path: "/Register",
    component: ()=>import('../views/Register.vue'),
    name: "Register"
  },
  {
    path:'/stat',
    name:'stat',
    redirect:'/home',
    component:()=>import('../views'),
    children:[
      {
        path:'/home',
        component:()=>import('../views/Home.vue'),
        meta:{ keepAlive: true,title:'即时销售'}
      },
      {
        path:'/commodityManagement',
        component:()=>import('../views/CommodityManagement.vue'),
        meta:{ keepAlive: true,title:'本地商品'}
      },
      {
        path: '/balaPayAccount',
        component: BalaPayAccount,
        meta: { keepAlive: true, title: '收支账户' }
      },
      {
        path: '/procurementPlan',
        component: procurementPlan,
        meta: { keepAlive: true, title: '采购计划' }
      },
      {
        path:'/customerManagement',
        component:()=>import('../views/Client/customerManagement.vue'),
        meta:{ keepAlive: true, title:'客户管理'}
      },
      {
        path:'/InvoiceManage',
        component:()=>import('../views/financialManage/InvoiceManage.vue'),
        meta:{ keepAlive: true, title:'发票管理'}
      },
      {
        path: '/Supplier',
        component: Supplier,
        meta: { keepAlive: true, title: '供应商' }
      },
      {
        path: '/Paymentorder',
        component: ()=>import('../views/financialManage/Paymentorder.vue'),
        meta: { keepAlive: true, title: '付款单' }
      },
      {
        path: '/collectionAccount',
        component: ()=>import('../views/financialManage/collectionAccount.vue'),
        meta: { keepAlive: true, title: '收款单' }
      },
      {
        path: '/menuManagement',
        component: ()=>import('../views/BasicManagement/menuManagement.vue'),
        meta: { keepAlive: true, title: '菜单管理' }
      },
      {
        path: '/role',
        component: ()=>import('../views/BasicManagement/role.vue'),
        meta: { keepAlive: true, title: '角色管理' }
      },
      {
        path: '/moneyFlowing',
        component: ()=>import('../views/financialManage/moneyFlowing.vue'),
        meta: { keepAlive: true, title: '资金流水' }
      },
      {
        path: '/procurementOrder',
        component: ()=>import('../views/Procurement/procurementOrder.vue'),
        meta: { keepAlive: true, title: '采购单' }
      },
      {
        path:'/shopAuthorization',
        name:'shopAuthorization',
        component:()=>import('../views/shopAuthorization'),
        meta:{ keepAlive: true, title:'店铺授权' }
      },
      {
        path:'/childAccount',
        name:'childAccount',
        component:()=>import('../views/shopAuthorization/childAccount.vue'),
        meta:{ keepAlive: true, title:'子账号' }
      },
      {
        path:'/dataSync',
        name:'dataSync',
        component:()=>import('../views/shopAuthorization/dataSync.vue'),
        meta:{ keepAlive: true, title:'数据同步' }
      },
      {
        path:'/stockControl',
        name:'stockControl',
        component:()=>import('../views/stockControl/stockControl.vue'),
        meta:{ keepAlive: true, title:'库存管理' }
      },
      {
        path:'/inboundManual',
        name:'inboundManual',
        component:()=>import('../views/stockControl/inboundManual.vue'),
        meta:{ keepAlive: true, title:'入库单' }
      },
      {
        path:'/outBoundManual',
        name:'outBoundManual',
        component:()=>import('../views/stockControl/outBoundManual.vue'),
        meta:{ keepAlive: true, title:'出库单' }
      },
      {
        path:'/ReceivingOrder',
        name:'ReceivingOrder',
        component:()=>import('../views/stockControl/ReceivingOrder.vue'),
        meta:{ keepAlive: true, title:'收货单' }
      },
      {
        path:'/quality',
        name:'quality',
        component:()=>import('../views/stockControl/quality.vue'),
        meta:{ keepAlive: true, title:'质检单' }
      },
      {
        path:'/salesStorehouse',
        name:'salesStorehouse',
        component:()=>import('../views/stockControl/salesStorehouse.vue'),
        meta:{ keepAlive: true, title:'销售出库单' }
      },
      {
        path:'/inventoryFlow',
        name:'inventoryFlow',
        component:()=>import('../views/stockControl/inventoryFlow.vue'),
        meta:{ keepAlive: true, title:'库存流水' }
      },
      {
        path:'/adjustTheSingle',
        name:'adjustTheSingle',
        component:()=>import('../views/stockControl/adjustTheSingle.vue'),
        meta:{ keepAlive: true, title:'调整单' }
      },
      {
        path:'/costGoods',
        name:'costGoods',
        component:()=>import('../views/stockControl/costGoods.vue'),
        meta:{ keepAlive: true, title:'商品成本' }
      },
      {
        path:'/productAnalysis',
        name:'productAnalysis',
        component:()=>import('../views/businessAnalysis/productAnalysis.vue'),
        meta:{ keepAlive: true, title:'商品分析' }
      },
      {
        path:'/stationAnalysis',
        name:'stationAnalysis',
        component:()=>import('../views/businessAnalysis/stationAnalysis.vue'),
        meta:{ keepAlive: true, title:'站点分析' }
      },
      {
        path:'/storeAnalysis',
        name:'storeAnalysis',
        component:()=>import('../views/businessAnalysis/storeAnalysis.vue'),
        meta:{ keepAlive: true, title:'店铺分析' }
      },
      {
        path:'/orderList',
        name:'orderList',
        component:()=>import('../views/businessAnalysis/orderList.vue'),
        meta:{ keepAlive: true, title:'订单列表' }
      },
      {
        path:'/returnOrder',
        name:'returnOrder',
        component:()=>import('../views/businessAnalysis/returnOrder.vue'),
        meta:{ keepAlive: true, title:'退换订单' }
      },
      {
        path:'/supplyCenter',
        name:'supplyCenter',
        component:()=>import('../views/stockControl/supplyCenter.vue'),
        meta:{ keepAlive: true, title:'补货中心' }
      },
      {
        path:'/salesProfile',
        name:'salesProfile',
        component:()=>import('../views/salesProfile.vue'),
        meta:{ keepAlive: true, title:'销售概要' }
      },
      {
        path:'/inventory',
        name:'inventory',
        component:()=>import('../views/warehouseStock/inventory.vue'),
        meta:{ keepAlive: true, title:'本地库存'}
      },
      {
        path:'/repository',
        name:'repository',
        component:()=>import('../views/warehouseStock/repository.vue'),
        meta:{ keepAlive: true, title:'本地仓库'}
      },
     {
        path:'/letianorder',
        name:'letianorder',
        component:()=>import('../views/orderCenter/letianorder.vue'),
        meta:{title:'乐天订单'}
      },{
        path:'/shopifyOrder',
        name:'shopifyOrder',
        component:()=>import('../views/orderCenter/shopifyOrder.vue'),
        meta:{title:'shopify订单'}
      },{
        path:'/logistics',
        name:'logistics',
        component:()=>import('../views/logisticsManagement/logistics.vue'),
        meta:{title:'物流管理'}
      },{
        path:'/freight',
        name:'freight',
        component:()=>import('../views/logisticsManagement/freight.vue'),
        meta:{title:'运费模板'}
      },
      {
        path:'/freightAdd',
        name:'freightAdd',
        component:()=>import('../views/logisticsManagement/freightAdd.vue'),
        meta:{title:'添加运费模板'}
      },
      {
        path:'/freightDetail',
        name:'freightDetail',
        component:()=>import('../views/logisticsManagement/freightDetail.vue'),
        meta:{title:'编辑运费模板'}
      },
      {
        path:'/freightDetails',
        name:'freightDetails',
        component:()=>import('../views/logisticsManagement/freightDetails.vue'),
        meta:{title:'运费模板详情'}
      },
      {
        path:'/warehouseSetting',
        name:'warehouseSetting',
        component:()=>import('../views/logisticsManagement/warehouseSetting.vue'),
        meta:{title:'仓库设置'}
      },
      {
        path:'/sendList',
        name:'sendList',
        component:()=>import('../views/sendList'),
        meta:{title:'发送列表'}
      },
      {
        path:'/requestRule',
        name:'requestRule',
        component:()=>import('../views/requestRule'),
        meta:{title:'请求规则'}
      },
      {
        path:'/analysisRule',
        name:'analysisRule',
        component:()=>import('../views/analysisRule'),
        meta:{title:'解析规则'}
      },
      {
        path:'/modifyPrice',
        name:'modifyPrice',
        component:()=>import('../views/Advertising/modifyPrice.vue'),
        meta:{ keepAlive: true, title: '定时策略' },
      },
      {
        path: '/personaldata',
        name: 'personaldata',
        component: ()=>import('../views/BasicManagement/personaldata.vue'),
        meta: { keepAlive: true, title: '个人资料' }
      },
      {
        path: '/vip',
        name: 'vip',
        component: ()=>import('../views/VIP/vip.vue'),
        meta: { keepAlive: true, title: '个人资料' }
      },
      {
        path:'/messageTemplate',
        component:()=>import('../views/mail/messageTemplate.vue'),
        meta:{ keepAlive: true, title:'消息模板'}
      },
      {
        path:'/mailMessage',
        component:()=>import('../views/mail/mailMessage.vue'),
        meta:{ keepAlive: true, title:'邮件消息'}
      },
      {
        path:'/requestRecord',
        component:()=>import('../views/mail/requestRecord.vue'),
        meta:{ keepAlive: true, title:'请求记录'}
      },
      {
        path:'/customerList',
        component:()=>import('../views/mail/customerList.vue'),
        meta:{ keepAlive: true, title:'客户列表'}
      },
      {
        path:'/Feedback',
        component:()=>import('../views/mail/Feedback.vue'),
        meta:{ keepAlive: true, title:'Feedback'}
      },
      {
        path:'/mailRules',
        component:()=>import('../views/mail/mailRules.vue'),
        meta:{ keepAlive: true, title:'邮件规则'}
      },
      {
        path:'/shipmentOrder',
        component:()=>import('../views/ShippingManage/shipmentOrder.vue'),
        meta:{ keepAlive: true, title:'自发货订单'}
      },
      {
        path:'/orderRules',
        component:()=>import('../views/ShippingManage/orderRules.vue'),
        meta:{ keepAlive: true, title:'订单规则'}
      },
      {
        path:'/orderMark',
        component:()=>import('../views/ShippingManage/orderMark.vue'),
        meta:{ keepAlive: true, title:'订单标发'}
      },
      {
        path:'/advertising',
        name:'advertising',
        redirect:'/advertising2',
        component:()=>import('../views/Advertising/index.vue'),
        children:[
          {
            path:'/advertising2',
            name:'advertising2',
            component:()=>import('../views/Advertising/advertising.vue'),
            meta:{ keepAlive: true, title:'广告商品' },
          },
          {
            path:'/activity',
            name:'activity',
            component:()=>import('../views/Advertising/activity.vue'),
            meta:{ keepAlive: true, title:'广告活动' },
          },
          {
            path:'/group',
            name:'group',
            component:()=>import('../views/Advertising/group.vue'),
            meta:{ keepAlive: true,  title:'广告组' },
          },
          {
            path:'/keyword',
            name:'keyword',
            component:()=>import('../views/Advertising/keyword.vue'),
            meta:{ keepAlive: true, title:'关键词' },
          },
          {
            path:'/launch',
            name:'launch',
            component:()=>import('../views/Advertising/launch.vue'),
            meta:{ keepAlive: true, title:'商品投放' },
          },
          {
            path:'/search_key',
            name:'search_key',
            component:()=>import('../views/Advertising/search_key.vue'),
            meta:{ keepAlive: true, title:'搜索词' },
          },
          {
            path:'/operationLog',
            name:'operationLog',
            component:()=>import('../views/Advertising/operationLog.vue'),
            meta:{ keepAlive: true, title:'操作日志' },
          },
          
        ]
      },
    ],
  },
  {
    path:'/entrance',
    name:'entrance',
    component:()=>import('../views/entrance.vue')
  },
  {
    path:'/*',
    name:'404',
    component:()=>import('../views/404.vue'),
    meta:{title:'404'}
  }
  
]

const router = new VueRouter({
  mode:'history',
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  if(to.path=='/shopify') return next();
  if(to.path=='/entrance') return next()
  if(to.path=="/login") return next()
  if(to.path=='/Register') return next();
  if(to.path=='/personaldata') return next();
  if(to.path=='/vip') return next();
  if(to.path=='/freightAdd') return next();
  if(to.path=='/freightDetail') return next();
  if(to.path=='/freightDetails') return next();
  if(to.path=='/warehouseSetting') return next();

  if(to.path=='/404') return next()
  if(to.path=='/home'){
    const token =window.sessionStorage.getItem('token')||''
    if(!token){
      const omsToken = Cookies.get('token')
      if(!omsToken){
        return next('/login')
      }else {
        window.sessionStorage.setItem('token',omsToken)
        Cookies.remove('token')
        return next()
      }
    }else{
      const token=window.sessionStorage.getItem('token')
      // Cookies.set("token", token, { domain: ['localhost', '127.0.0.1','192.168.1.111'].includes(document.domain) ? document.domain:".cloudkinto.net"})
      return next()
    }
  }
  const tokenStr = sessionStorage.getItem("token")||'';
  const menuList=window.sessionStorage.getItem('menuList')||''
  if(to.path=='/shopAuthorization'){
    if(!tokenStr){
      if(JSON.stringify(to.query)=='{}'){
        return next('/login')
      }else {
        window.sessionStorage.setItem('accredit',JSON.stringify(to.query))
        return next('/login')
      }
    }else {
      return next()
    }
  }
  if (!tokenStr){
    return next("/login");
  }else{
    if(!menuList) return next('/login')
    if(menuList.indexOf(to.path)<0){
      return next('/404')
    }else {
      return next()
    }
  }
  next();
})

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
    // router.replace(router.history.pending.fullPath);
  }else{
    console.log(error)
  }
});

  
export default router

import Vue from 'vue'
import Vuex from 'vuex'
// import router from 'vue-router'
// import {Login} from '../api/login.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    num:0,
    shopInfo:'',
    user:{
      // headUrl:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      headUrl:'',
      userAccount:'',
      userName:'',
      userId:''
    },
    crumbs:[{title:'首页',url:'/home',name:'/home'}],
    activeTab:'/stat',
    profileId:'',
    advName:'',
    messagenumber: 0
  },
  mutations: {
    setAdvName(state,data){
      state.advName=data
    },
    setProfileId(state,data){
      state.profileId=data
    },
    setNum(state){
      state.num++
    },
    setUser(state,data){
      state.user=JSON.parse(window.sessionStorage.getItem('user'))
    },
    shuaxin(state){
      state.crumbs=JSON.parse(window.sessionStorage.getItem('crumbs'))||[{title:'首页',url:'/home',name:'/home'}]
    },
    getMessageNumber(state, data) {
      state.messagenumber = data;
    },
    setCrumbs(state,data){
      const crumbsList=state.crumbs
      crumbsList.push(data)
      window.sessionStorage.setItem('crumbs',JSON.stringify(crumbsList))
      state.crumbs=JSON.parse(window.sessionStorage.getItem('crumbs'))||[{title:'首页',url:'/home',name:'/home'}]
    },
    setActiveTab(state,data){
      state.activeTab=data
    },
    remove(state,data){
      if(data==0) return ;
      state.crumbs.splice(Number(data),1)
      window.sessionStorage.setItem("crumbs",JSON.stringify(state.crumbs))
    },
  },
  actions: {
   
  },
  modules: {
  }
})

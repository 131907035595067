<template>
  <span>
        <span class="copyBox">
            <slot name='copy' class="copyContent"></slot>&nbsp;
            <i class="el-icon-document-copy" @click.self="copy"></i>
        </span>
  </span>
</template>

<script>
export default {
    props:['content'],
    methods:{
        copy(){
            const input = document.createElement('input');
            input.value = this.content;//将需要复制的内容赋值给input框
            document.body.appendChild(input);
            input.select()//选中输入框
            document.execCommand('copy')//复制当前选中文本到前切板
            document.body.removeChild(input);
            this.$message.success('复制成功')
        },
    }
}
</script>

<style scope lang='scss'>
.copyBox{
    i{
        visibility:hidden;
        cursor: pointer;
    }
}
.copyBox:hover{
    i{
        visibility:visible;
    }
}
</style>